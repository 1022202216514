.contact {
  padding: 50px;
  margin-top: 50px;
}

.formDivMain {
  display: flex;
  flex: 50%;
  background-color: white;
  /* margin: 8% 8% 0% 8% ; */
  margin-left: 5%;
  margin-right: 5%;
  -webkit-box-shadow: 0px 0px 120px -35px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 120px -35px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 120px -35px rgba(0, 0, 0, 0.75);
  /* border-radius: 10px; */
}
.formDivMain > div {
  width: 50%;
}
.left {
  margin-left: 10%;
}
.right {
  width: 30% !important;
  background-color: #5cdb95;
  margin-left: 10%;
}
.formDiv input {
  width: 40%;
  height: 30px;
  margin: 10px;
}
.address {
  padding: 30px;
}
@media (max-width: 600px) {
  .formDivMain {
    flex-direction: column;
    width:98% !important;
    margin: auto;
   
  }
  .formDivMain > div {
    width: 100%;
  }
  .formDiv input {
    width: 80%;
    height: 30px;
    margin: 10px;
  }
  .right {
    width: 100% !important;
    background-color: #5cdb95;
    margin-left: 0%;
    
  }
  .left {
    margin-left: -5%;
    padding-left: 5%;
  }
  .contact {
    padding: 0px;
    margin-top: 100px;
  }
  .address {
    padding: 0px;
  }
}
