.breadcrumbs
{
   position: relative;
   /* margin-top: 50px; */
   top: 20px;
   /* left: 55px; */
   font-size: 18px;
   font-weight: bold;  
}

.cards {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
  background-color: black;
  overflow-y: hidden;
  
}


.cards > div {
  margin-top: 100px;
  margin-bottom: 20px;
  /* width: 28%; */
  /* height: 100%; */
  background-color: white;

  border-radius: 10px;
}
.cards > div:hover {
  margin-top: 90px;
  transition: 0.3s;
  -webkit-box-shadow: 0px 0px 36px 0px rgba(92,219,149,0.53);
  -moz-box-shadow: 0px 0px 36px 0px rgba(92,219,149,0.53);
  box-shadow: 0px 0px 36px 0px rgba(92,219,149,0.53);
}
.cardContent {
  padding: 20px;
}
.cardContent span
{
    font-weight: 600;
}
@media (max-width:600px)
{
    .cards
    {
        flex-direction: column;
    }
    .cards > div
    {
        position: relative;
        width: 90%;
        margin-left: 5%;
        height: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 10px;
    }
    .cards > div:hover {
      position: relative;
      width: 90%;
      margin-left: 5%;
      height: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: white;
      border-radius: 10px;
    }
}