.about3
{
   height: 600px;
   background-color:black;
   padding-top: 40px;

}

.innerContainer
{
    display: flex;
    background-color: white;
    width: 90%;
    height: 500px;
    /* border: solid red 1px; */
    border-radius: 100px;
    margin-left: 5%;
    padding-top: 30px;
}



.leftDiv
{
    position: relative;
    width: 90%;
    margin-left: 5%;
    top: 50px;
}
.rightdiv
{
 width: 50%;
 margin-left: 100px;
 margin-top: 50px;
 /* position: absolute; */
}
.backImage
{
   
    position: absolute;
    /* z-index: 100; */
    width: 20%;
    overflow: hidden;
    
    border-radius: 20px;
}


.frontImage
{
    position: absolute;
    width: 20%;
    z-index:0;
    margin-left: 90px;
    border-radius: 20px;
}

.bar span:last-of-type
{
    margin-left: 300px;
}


.bar progress  {
    width: 100%; 
    height: 1rem;
    text-align: center;
    color: white;
}
@media (max-width:600px) {
    .about3
{
   height: 700px;
   background-color:black; 
   padding-bottom: 30px;

}
.innerContainer
{
    flex-direction: column;
    background-color: white;
    width: 90%;
    height: 700px;
    border-radius: 10px;
    margin-left: 5%;
    padding-top: 0px;
}
.leftDiv
{
    position: relative;
    width: 90%;
    margin-left: 5%;
    top: 43%;
}
.rightdiv
{
    position: relative;
    bottom: 360px;
    right: 80px;
 width: 50%;
 margin-left: 100px;
 margin-top: 50px;
 /* position: absolute; */
}
.backImage
{
  bottom: 150px;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
}
.frontImage
{
    position: relative;
    width: 100%;
    z-index:0;
    margin-left: 90px;
    border-radius: 20px;
}
.bar span:last-of-type
{
    margin-left: 90px;
}
    
}