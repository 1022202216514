*{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'El Messiri';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-prev:before,
.slick-next:before {
  color: white !important;
  opacity: 3;
  font-size: 30px;
}
.slick-dots li button:before {
  font-size: 90px;
  line-height: 18px;
  position: absolute;
  top: -140px;
  left: -376px;
  width: 20px;
  height: 20px;
  content: '-';
  text-align: center;
  opacity: 2;
  color: white;
}

@media (min-width:600px) and (max-width:800px) {
  body
  {
    display: none;
  }
  
}

.content strong {
  font-weight: 700;
  color: white;
  font-size: 25px;
}
.content img {
  border-radius: 15px;
}

.content p{
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 15px;
}
/* .content p:first-child{
  padding-top: 0px !important;
} */


@media (max-width:600px) {
  .content{
    flex-direction: column !important;
    /* padding: 20px; */
    padding-top: 20px;
    border-bottom: 1px solid white;
  }  
  .content p{
    font-size: 17px !important;
    padding-left: 0px;
    padding-right: 0px;
    
  }
}
