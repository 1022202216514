.arrow {
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.submenu {
  display: none;
  position: absolute;
  list-style: none;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 35px;
  background-color: rgb(0, 0, 0);
}

@media (max-width: 600px) {
  .submenu {
    position: relative;
    padding-top: 0px;
    background-color: black;
  }
}

.submenu li {
  /* background-color: gray; */
  /* border-bottom: 1px solid red; */
  width: 8vw;
  margin: 3px;
  color: rgb(255, 255, 255);
  width: fit-content;
  padding: 10px;
}
.submenu li a {
  /* background-color: gray; */
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  font-size: 16px;
}
.visible:hover .submenu {
  /* display: block; */
  display: flex;
}

.NavBar {
  /* border: 1px solid red; */
  height: 93px;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-evenly;
  background-color: #ffff;
  /* firetransition: background 1s; */
  z-index: 1;
}
.NavBar2 {
  height: 100px;
  display: flex;
  position: fixed;
  background-color: #cecece;
  width: 100%;
  justify-content: space-evenly;
  background-color: transparent;
  z-index: 1;
}

.logo {
  margin-top: 6px;
  /* margin-top: -30px; */
  /* font-size: 30px; */
  margin-left: -30px;
  color: #5cdb95;
}
.logo img {
  /* position: relative; */
  /* bottom: 15px; */
}
.NavItems {
  /* border: solid red 1px; */
  width: 1000px;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  margin-left: 240px;
  /* margin-top: -45px;  this is for when we add logo*/
  margin-top: -64px;
}

.NavItems li {
  font-weight: bold;
  color: black;
  font-size: 18px;
}
.NavItems li::after {
  content: "";
  background: #5cdb95;
  width: 100%;
  height: 2px;
  display: block;
  width: 0%;
  /* transform: 1s; */
}
.NavItems li:hover::after {
  width: 100%;
  transition: 0.5s;
}
.NavItems li a {
  text-decoration: none;
  color: black;
  margin-top: 15px;
}

/* for color changing set this css */
.navItemscolor {
  /* border: solid red 1px; */
  width: 1000px;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  margin-left: 240px;
  /* margin-top: -45px;  this is for when we add logo*/
  margin-top: -64px;
}

.navItemscolor li {
  font-weight: bold;
  color: rgb(255, 255, 255) !important;
  font-size: 18px;
}
.navItemscolor li::after {
  content: "";
  background: #5cdb95;
  width: 100%;
  height: 2px;
  display: block;
  width: 0%;
  /* transform: 1s; */
}
.navItemscolor li:hover::after {
  width: 100%;
  transition: 0.5s;
}
.navItemscolor li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  margin-top: 15px;
}

.headerButton {
  width: 250px;
  height: 50px;
}
.mobileemail {
  margin-left: 800px;
  margin-top: -30px;
  color: black;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.contact div {
  display: flex;
}

.navButton {
  display: inline-block;
  padding: 0.5em 1em;
  text-align: center;
  color: black !important;
  font-size: 18px;
  background: #5cdb95;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to left, #5cdb95 50%, #f5f5f5 50%) right;
  background-size: 200%;
  transition: 0.1s ease-out;
  position: relative;
  margin-top: -7px;
}
.navButton:hover {
  background-position: left;
  color: black !important;
}
.socialMedia2 {
  display: flex;
  position: relative;
  margin-left: 1250px;
}
.socialMedia {
  display: none;
}
.mobilemenu {
  display: none;
}
@media (max-width: 600px) {
  .submenu li {
    /* background-color: gray; */
    /* border-bottom: 1px solid red; */
    /* width: 8vw;
    margin: 3px;
    color: black;
    width: fit-content; */
    padding: 0px;
  }
  .NavBar {
    display: block;
    position: fixed;
    overflow-y: scroll;
    margin-left: 30%;
    width: 70%;
    float: right;
    z-index: 1;
    background: #cecece;
    height: 100%;
    overflow-x: hidden;
    transition: 300ms ease-out;
  }
  .NavBar2 {
    display: block;
    position: fixed;
    width: 70%;
    margin-left: 30%;
    float: right;
    z-index: 1;
    background: #cecece;
    height: 100%;
    overflow-x: hidden;
    transition: 300ms ease-out;
  }
  .NavBara {
    display: none;
  }
  .NavBarab {
    display: none;
  }
  .NavItems {
    width: 400px;
    display: block;
    margin-top: 15px;
    margin-left: 0%;
    /* overflow-y: auto !important; */
  }
  .navItemscolor {
    width: 400px;

    display: block;
    margin-top: 15px;
    margin-left: 0%;
  }
  .socialMedia2 {
    display: flex;
    position: relative;
    margin-left: 1000px;
  }
  .socialMedia {
    display: flex;
    position: relative;

    margin-left: 1000px;
    /* justify-content: center; */
  }
  .mobileemail {
    margin-left: 0%;
    position: relative;
    display: grid;
    top: 30px;
    /* margin-top: 370px; */
    color: rgb(0, 0, 0);
    font-size: 18px;
  }
  .mobileemail div {
    height: 40px;
    padding: 20px;
    width: 100% !important;
    margin-top: 10px;
    border-radius: 15px;
  }
  .navButton {
    display: inline-block;
    padding: 0.6em 1em;
    text-align: center;
    color: black !important;
    font-weight: bold;
    font-size: 18px;
    background: #5cdb95;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to left, #5cdb95 50%, #f5f5f5 50%) right;
    background-size: 200%;
    transition: 0.1s ease-out;
    position: relative;
    margin-top: 20px;
    margin-left: -60px;
  }
  .navButton:hover {
    background-position: left;
    color: black !important;
  }
  .NavItems li {
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin-top: 15px;
  }
  .NavItems li::after {
    display: none;
  }

  .NavItems li a {
    text-decoration: none;
    color: rgb(0, 0, 0);
  }
  .NavItems li a:active {
    color: black !important;
  }

  /* for color change useState */

  .navItemscolor li {
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin-top: 15px;
  }
  .navItemscolor li::after {
    display: none;
  }

  .navItemscolor li a {
    text-decoration: none;
    color: rgb(0, 0, 0);
  }
  .navItemscolor li a:active {
    color: black;
  }

  .socialMedia2 {
    display: flex;
    position: relative;
    margin-left: 30px;
    padding-top: 90px;
  }
  .socialMedia {
    display: flex;
    position: relative;
    margin-left: 30px;
    padding-top: 60px;
  }
  .logo {
    /* margin-top: -30px;
    font-size: 20px;
   text-align:  center;
   margin-left: -20px;
   margin-top: 10px;
    color: #5cdb95; */
  }
  .logo img {
    position: relative;
    bottom: 200px;
    left: 20px;
    width: 236px;
  }
  .menu_btn {
    position: fixed;
    z-index: 1;
    right: 35px;
    top: 35px;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }

  /* Styling the hamburger lines */
  .menu_btn .btn_line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: #156531;
    transition: all 0.5s ease-out;
  }
  .visible:hover .submenu {
    display: block;
    /* display: flex; */
    overflow-y: scroll;
    width: fit-content;
  }
}

.mobno button {
  background-color: wheat;
  color: black;
  font-weight: bold;
  font-size: 20px;
  border: none;
  border-radius: 5px;
}
.email {
  display: flex;
  position: relative;
  right: 200px;
  align-items: center;
  gap: 20px;
}
.email button {
  background-color: transparent;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 15px;
  border: none;
  border-radius: 5px;
}
.goToTop {
  position: fixed;
  top: 530px;
  left: 95%;
  z-index: 99;
  background-color: white;
  font-size: 20px;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
@media (max-width: 600px) {
  .mobileNavBar {
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 600px;
    height: 90px;
    position: fixed;
  }
  .email {
    padding-top: 50px;
    text-align: left;
    display: block;
    position: relative;
    right: 0px;
    align-items: center;
    gap: 0px;
  }
  .email button {
    border: solid 2px rgb(0, 0, 0);
    margin-top: 15px;
    color: black;
  }
  .email button a {
    color: black !important;
  }
  .goToTop {
    position: fixed;
    top: 530px;
    left: 81%;
    z-index: 99;
    background-color: white;
    /* border-radius: 100%;
  padding: 20px; */
    width: 50px;
    border: none;
    /* font-size: 20px; */
    height: 50px;
    border-radius: 50px;
  }
}
