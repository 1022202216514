
.section4 { 
  /* margin-top: -80px; */
  position: relative;
  width: 100%;
  height: 110vh;
  display: flex;
  /* background-color: black; */
}

section .imgbx {
  position: relative;
  width: 50%;
  height: 100%;
}

/* section .imgbx img {
  position: absolute;
  top: 15%;
  left: 30%;
  border-radius: 40px;
} */

.img1
{
  position: absolute;
  top: 80px;
  left: 100px;
  width: 50%;
  border-radius: 40px;

}
.img2
{
  position: absolute;
  top: 250px;
  left: 250px;
  width: 45%;
  border-radius: 40px;
}
.contentbx{
  height: fit-content;
  BACKGROUND-COLOR: #474336;
  BORDER-RADIUS: 10PX;
  margin-top: 50px;
  padding: 20px;
  width: 30%;
}
.formbx h1{
  color: #5cdb95;
}
.formbx p
{
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-align: center;

}
.formbx 
{
  text-align: center;
  /* margin-top: 80px; */
  
}

.formContainer
{
  position: relative;
  /* margin-bottom: 90px; */
  /* top: 50px; */
}


.formContainer input
{
  width: 80%;

  height: 45px;
  font-size: 15px;
  border: none;
  /* border: solid 1px black; */
  border-radius: 5px;

}
.formContainer textarea
{
  width: 80%;
  height: 110px;
  font-size: 18px;
  margin: 20px;
}
.navButton {
  display: inline-block;
  padding: 0.5em 1em;
 text-align: center;
  color: black !important;
  font-size: 18px;
  background: #5cdb95;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to left, #5cdb95 50%, #f5f5f5 50%) right;
  background-size: 200%;
  transition: 0.1s ease-out;
  position: relative;
  margin-top: -7px;
  /* margin-right: 10px; */
 /* margin-left: 30px; */
}
.navButton:hover {
  background-position: left;
  color: black !important;
}






@media (max-width:600px) {
  .section4
  {
    margin-top: -20px;
    flex-direction: column;
    flex-basis: auto;
    height: 1000px;
  }
  .img1
{
  position: absolute;
  top: 40px;
  left: 10%;
  width: 100%;
  border-radius: 40px;

}
.img2
{
  position: absolute;
  top: 120px;
  left: 90%;
  width: 100%;
  border-radius: 40px;
}
.formbx
{
  padding-bottom: 5%;
  text-align: center;

}
.formContainer
{
  top: 0px;
  margin-bottom: 0px;
}
.contentbx{
  height: fit-content;
  BACKGROUND-COLOR: #474336;
  BORDER-RADIUS: 10PX;
  /* margin-top: 50px; */
  /* padding: -1px; */
  width: 80%;
  margin: auto;
  margin-bottom: 35px;
}
}