.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color:#282c34;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 30px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .topcontent
{
  font-size: 20px !important;
  line-height: 35px!important;
  font-weight: 200 !important;
}
.topcontent p{
  color: white !important;
}
.topcontent ul li
{
  color: white !important;
  font-size: 20px !important;
  line-height: 35px!important;
  font-weight: 200 !important;
} 

.helloleft
{
  display: flex;
  gap: 20px;
  padding-top: 30px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 15px;
  background-color: #5cdb95;
}
.helloleft p{
  color: black !important;
  font-size: 20px !important;
  line-height: 35px!important;
}
@media (max-width:600px) {
  .helloleft{
    flex-direction: column;
  }
  
}

.helloright
{
  display: flex;
  gap: 20px;
  padding-top: 40px !important;
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: #ffffff;

 
}
@media (max-width:600px) {
  .helloright{
    flex-direction: column;
  }
  
}
.helloright p{
  color:rgb(0, 0, 0) !important;
  font-size: 20px !important;
  line-height: 35px!important;
}
@media (max-width:600px) {
  .helloleft{
    flex-direction: column;
  }
  
} */
