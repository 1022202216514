.bodyCoontiner
{
    background-color: black;
    padding-top: 30px;
    display: grid;
    grid-template-columns: 70% 30%;
}
.leftcontainer
{
    padding: 30px;
    padding-left: 90px;
}
.servicecontent
{
    text-align: justify;
}
.servicecontent h1
{
   color: white;
}
.servicecontent h2
{
    color: #ffffff!important;
    font-size: 25px!important;
    font-weight: 600!important;
    margin: 25px 0!important;
}

.servicecontent p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 10px 0;
    text-align: justify;
}
.servicecontent ul li{
    color: #dbdbdb;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin: 10px 0;
    text-align: justify;
}

@media (max-width:600px) {
    .bodyCoontiner
{
    /* background-color: black;
    padding-top: 30px; */
    padding: 10px;
    display: grid;
    grid-template-columns: 100%;
}
.leftcontainer
{
    /* padding: 30px; */
    padding-left: 0px;
}
    
}