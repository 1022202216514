.visionContainer {
  background-image: url("../../../../assets/images/mission/vission_miison.jpg");
  background-position: center;
  width: 100%;
  height: 500px;
  display: flex;
}
.visionContainer > div {
  width: 35%;
}
.visionDesription {
  height: 88%;
  margin: 0 auto;
  background: linear-gradient(100deg, rgb(32 44 38/0.8), rgb(32 44 38/0.5));
  opacity: 0.8;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.visionTitle {
  border-bottom: 5px solid #fff;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #fff;
  font-weight: bold;
  opacity: 1 !important;
}
.visionDesc {
  font-size: 19px;
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  text-align: justify;
}

@media (max-width: 576px) {
 
  .visionContainer {
    background-image: url("../../../../assets/images/mission/vission_miison.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .visionDesription {
    width: 75%;
  }
  .visionContainer {
    flex-direction: column;
  }
  .visionContainer > div {
    width: 77%;
    margin-top: 30px;
  }
}