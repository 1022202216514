.mainDivision
{
    /* background-color: rgb(153, 131, 131); */
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: black;
    padding: 5%;
}

.heading
{
    text-align: center;
}
.heading p
{
    font-weight: 600;
    color: rgb(166, 166, 206);
 
}
.heading h1
{
    font-size: 40px;
    padding-bottom: 50px;
    color: white;
}

.processdiv
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.circle
{
    width: 60px;
    height: 60px;
 
    border-radius: 30px;
    background-color: rgb(92, 219, 149);;
    margin: 0 auto;
}
.circle > span
{
    text-align: center;
    position: relative;
    left:40%;
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 25px;
    top: 10px;
}
.circle:hover
{
    -webkit-box-shadow: 0px 0px 124px 68px rgba(92,219,149,0.48);
    -moz-box-shadow: 0px 0px 124px 68px rgba(92,219,149,0.48);
    box-shadow: 0px 0px 124px 68px rgba(92,219,149,0.48);
    transition: 1s;
}
.arrow
{
    border-bottom: 1px solid white;
    width: 50%;
    position: relative;
    left: 75%;
    bottom:28px;
    opacity: 0.3;
}
.discription
{
    text-align: center;
    
   
}
.discription p
{
  font-weight: 600;
    padding: 10px;
    color: white;
}

.discription h2
{
    color: white;

}
@media (max-width:600px) {
.processdiv
{
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.arrow
{
    border-bottom: none;
    width: 50%;
    position: relative;
    left: 75%;
    bottom:28px;
    opacity: 0.3;
}
    
}