.mainContainer
{
    background-color: black;
    padding: 35px;
    color: white;
}
.headingContent
{
    text-align: center;
    margin-bottom: 50px;

}
.About4
{
    display: flex;
    justify-content: space-evenly;
}
.circle img {
    background: lightblue;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    z-index: 1;
}
@media (max-width:600px) {
    .circle img {
        width: 100%;
        height: 100%;

    }
    
}

.circle img + .hide
{
    position: relative;
    bottom:80px;
    left: 70px;
    opacity:0.1;
}
.circle img:hover {
    background-color: black;
    opacity: 2;
    filter: blur(1px);
    
}
.circle img:hover + .hide
{
    /* font-size: 30px; */
    display: block;
    color: red;
    opacity: 1;
    position: relative;
    bottom:80px;
    left: 70px;
}
@media (max-width:600px) {
    .circle img:hover + .hide
{
    bottom: 114px;
    left: 119px
}
    
}

.slick-next:before {
    color: black !important;
}