.header{
   /* border: solid 1px red; */
   padding-top: 5%;
   padding-bottom: 2%;
  }
  
  .header h1 {
    /* position: relative; */
    /* background:#5cdb95; */
    color: white !important;
    padding: 10px;
    text-align: center;
    
    margin: auto;
    /* margin-top: 5%; */
   
    margin-bottom: 2%;
  
    width: fit-content;
    font-size: 36px;
    font-family: "Times New Roman", Times, serif;
    
    border-radius: 10px;
  }
  @media (max-width:600px) {
    .header h1{
      font-size: 25px;
    }
  }
  .section_2_main {
    margin-top: -28px;
    height: 500px;
    /* background-color: #5cdb95; */
    /* background-color: #fce181;; */
    background-color: #000000;
    
  }
  /* .section_2_main:hover .header p
  {
    -webkit-box-shadow: 0px 200px 159px 149px rgba(92,219,149,0.54);
    -moz-box-shadow: 0px 200px 159px 149px rgba(92,219,149,0.54);
    box-shadow: 0px 200px 159px 149px rgba(92,219,149,0.54);
    transition: 500ms;
  } */

  @media (max-width:600px) {
    .header{
      /* border: solid 1px red; */
      padding-top: 10%;
      padding-bottom: 0%;
     }
    .header p
    {
      margin-top: 0;
      margin-bottom: 100px;
    color:black;
    width: fit-content;
    font-size: 25px;
    font-family: "Times New Roman", Times, serif;
   
    border-radius: 10px;


    }

    
  }
  .section_2 {
    /* border: 2px solid red; */
    margin-top: -15px;
    margin: 0 auto;
    display: flex;
    /* height: 500px; */
  }
  .section_2 > div {
    /* border: 2px solid red; */
    width: 50%;
  }
  .section_2_img {
    /* border: 2px solid red; */
    position: relative;
    margin-top: 30%;
  }
  .img_1 {
    position: absolute;
    bottom: 82px;
    left: 10%;
    width: 40%;
    border-radius: 30px;
  }
  .img_2 {
    position: absolute;
    bottom: 229px;
    left: 40%;
    width: 40%;
    border-radius: 30px;
    
  }
  
  .section_2_content {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .section_2_content h3 {
    font-size: 28px;
    color: #5cdb95;
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    line-height: 1.5;
  }
  
  .section_2_content p {
    text-align: justify;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 3%;
    padding-right: 5px;
    padding-right: 70px;
  }
  .content_data ul{
    color: white;
    font-size: 22px;
    line-height: 30px;
  
  }
  
  /* .section_2_content button {
    padding: 3%;
    margin: 2%;
    background-color: #d0a37c;
    color: white;
    margin-top: 5%;
    border-radius: 20px;
    border: 1px white;
    font-size: large;
  }
  
  .section_2_content button:hover {
    background-color: #3a384e;
    border: none;
  } */

  

  .buttondiv
  {

    margin-top :80px;
    text-align: center;
    }
  @media (max-width:600px) {
    .buttondiv
  {
    margin-top :0px;
  }
    
    
  }
  .navButton {
    display: inline-block;
    padding: 0.5em 1em;
   text-align: center;
    color: black !important;
    font-size: 18px;
    background: #5cdb95;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to left, #5cdb95 50%, #f5f5f5 50%) right;
    background-size: 200%;
    transition: 0.1s ease-out;
    position: relative;
    
    margin-top: -7px;
    margin-right: 10px;
      }
  .navButton:hover {
    background-position: left;
    color: black !important;
  }
  @media (max-width:600px) {
    .section_2_main
    {
     
      padding-bottom: 15px;
      height: 100%;
    }
    .section_2
    {
      display: block;
    }
    .section_2 > div
    {
      width: 100%;
    }
    .section_2_img {
      margin-top: 90%;
      padding-bottom:0% ;
    }
    .img_1 {
      position: absolute;
      bottom: 0px;
      left: 2%;
      width: 50%;
      border-radius: 30px;
    }
    .img_2 {
      bottom: 80px;
      left: 43%;
      width: 50%;
      border-radius: 30px;
    }
    .section_2_content h3
    {
      text-align: center;
      font-size: 22px;


    }
    .section_2_content p
    {
      text-align: justify;
      font-size: 15px;
      padding: 10px;
    }
    .content_data ul li{
      color: white;
      font-size: 15px;
      line-height: 25px;
    }
    .navButton {
      font-size: 12px;
      margin-left: auto;
    }
    
   
  }