.mainContainer
{
    background-color: black;
    padding: 90px;
    

}
.headingContent
{
    text-align: center;
    margin-bottom: 50px;
    color: white;

}
.About4
{
    display: flex;
    justify-content: space-evenly;
}

.circle img {
    background: lightblue;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    z-index: 1;
    margin-left: 42%;
    /* margin-left: auto; */
   
}
@media (max-width:600px) {
    .circle img {
        margin:auto;
    }
    .mainContainer
{
    padding: 35px;
}
    
}
.circle img:hover + .hide
{
    /* font-size: 30px; */
    display: block;
    /* color: rgb(255, 255, 255); */
    opacity: 1;
    position: relative;
    bottom:80px;
    left: 70px;
}


.testimonial
{
    border: solid 1px rgb(255, 255, 255);
    border-radius: 30px;
    width: 80% !important;
    margin-left: 10%;
    padding: 10px;
}

